import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router";
import { Col, Container, Row, Alert } from "react-bootstrap";
import UserTipSidebar from "../UI/UserIdeaSidebar/UserTipSidebar";
import Comments from "../UI/Comments/TipComments";
import "./IdeaDetail.scss";
import editIcon from "../../assets/InnovativeIdeas-Icons/PNG/edit.png";
import SearchDropDown from "../FormElements/SearchDropdown";
import TextArea from "../FormElements/Textarea";
import { useLocation, navigate } from "react-router-dom";
import {
  GetTipDetails,
  EditTipDetails,
  approveTipDetail,
  GetIdeaLikes,
  GetIdeaInterested,
} from "../../services/TipDetails-Service";
import AuthContext from "../../store/auth-context";
import arrowLeft from "../../assets/arrowLeft.png";
import { getTipHistory } from "../../services/History-service";
import History from "../UI/History/History";
import { DeleteTip, UploadAttachments } from "../../services/Tip-Services";
import { LikeIdea, ShowInterestIdea } from "../../services/IdeaDetails-Service";
import isInterested from "../../assets/InnovativeIdeas-Icons/PNG/isInterested.png";
import hands from "../../assets/hands.png";
const TipDetail = (props) => {
  // const [allocateModalShow, setAllocateModalShow] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const authCtx = useContext(AuthContext);
  const [tipDetails, setTipDetails] = useState({});
  const [edit, setEdit] = useState(false);
  const [pageNo, setPageNo] = useState(1);

  const [formValues, setFormValues] = useState({});
  const [formErrors, setFormErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);

  const [selectedTab, setSelectedTab] = useState(0);

  const [visible, setVisible] = useState(15);
  const [historyData, setHistoryData] = useState([]);

  // const [availablecategories, setAvailableCategories] = useState([]);
  // const [selectedCategory, setSelectedCategory] = useState({
  //   value: "",
  //   label: "",
  // });
  const [availabletypes, setAvailableTypes] = useState([]);
  const [availableProducts, setAvailableProducts] = useState([]);
  const [availableRoles, setAvailableRoles] = useState([]);

  const [newStatus, setNewStatus] = useState("approve");
  const [newComplexity, setNewComplexity] = useState("simple");
  const [adminComments, setAdminComments] = useState("");
  const [isCloseIdea, setIsCloseIdea] = useState(false);
  const [totalRecords, setTotalRecords] = useState(0);
  const [rolesList, setRolesList] = useState([{ name: "", skills: [] }]);
  const [rolesFormValues, setRolesFormValues] = useState([
    { name: "", skills: [] },
  ]);
  const [show, setShow] = useState(false);
  const [modalContent, setModalContent] = useState([]);
  const [count, setCount] = useState([]);
  // const [isAdminClickSave, setIsAdminClickSave] = useState(false);
  // const [isValidRoles, setIsValidRoles] = useState(false);
  const [isValidToAdd, setIsValidToAdd] = useState(false);
  // const [modalShow, setModalShow] = useState(false);
  const [modalType, setModalType] = useState("");
  const [initialValues, setInitialValues] = useState({});
  const [showSuccessMsg, setShowSuccessMsg] = useState(false);

  const handleClose = () => {
    setShow(false);
    setModalContent([]);
  };
  const handleShow = (type) => {
    setShow(true);
    setModalType(type);
    if (type == "like") {
      getLikes();
    } else {
      getInterest();
    }
  };

  const getLikes = async () => {
    await GetIdeaLikes(authCtx.token, location.state.id)
      .then((resp) => {
        if (resp) {
          setModalContent(resp.data);
          setCount(resp.count);
        }
      })
      .catch((error) => console.log(error));
  };

  const getInterest = async () => {
    await GetIdeaInterested(authCtx.token, location.state.id)
      .then((resp) => {
        setModalContent(resp?.data);
        setCount(resp.count);
      })
      .catch((error) => console.log(error));
  };

  const reviewStatus = [
    { label: "shortlisted", value: "Shortlisted" },
    { label: "rejected", value: "rejected" },
  ];

  const formElements = [
    {
      name: "title",
      errorMessage: "Please provide title in few words",
      label: "Let's give a name to your Tip*",
      required: true,
    },
    {
      name: "description",
      errorMessage: "description is required",
      label: "Tell us more about your tip*",
      required: true,
      maxLength: 1000,
    },
    {
      name: "references",
      errorMessage: "References is required",
      label: "Mention your references*",
      required: true,
      maxLength: 1000,
    },
    {
      name: "status",
      errorMessage: "Status is required",
      label: "Review Status*",
      required: false,
    },
  ];

  useEffect(() => {
    getTipDetails();
  }, []);

  const getTipDetails = () => {
    GetTipDetails(authCtx.token, location.state.id)
      .then((resp) => {
        console.log(resp);
        setTipDetails(resp?.records);
        let formInitials = {
          tip: resp?.records[0]?.tip,
          description: resp?.records[0]?.description,
          references: resp?.records[0]?.references,
          attachment: resp?.records[0]?.attachment,
          ReviewStatus: resp?.records[0]?.ReviewStatus,
        };
        setFormValues(formInitials);
        setInitialValues(formInitials);
      })
      .catch((error) => console.log(error));
  };

  const onChange = (e) => {
    setFormValues({ ...formValues, [e.target.name]: e.target.value });
  };
  const onSelect = (e) => {
    if (e.name == "ReviewStatus") {
      setFormValues({ ...formValues, [e.name]: e.value?.label });
    } else {
      setFormValues({ ...formValues, [e.name]: e.value?.value });
    }
    validateForm(formValues);
  };

  useEffect(() => {
    if (isSubmit) {
      setFormErrors(validateForm(formValues));
    }
  }, [formValues, isSubmit]);

  const submitForm = (e) => {
    e.preventDefault();
    const error = validateForm(formValues);
    console.log(error);
    const isvalid = Object.keys(error).length === 0 ? true : false;
    const files = formValues?.attachment?.filter((file) => file?.name);
    if (isvalid) {
      setFormErrors(error);
      if (files.length) {
        let formData = new FormData();
        files.forEach((item) => {
          formData.append("files", item.file, item.name);
        });
        saveAttachments(formData);
      } else {
        editTip(formValues);
      }
    } else {
      setFormErrors(error);
    }
  };
  const likeIdea = (status) => {
    let inputObj = { like: status };
    LikeIdea(authCtx.token, location.state.id, inputObj)
      .then((data) => {
        getTipDetails();
      })
      .catch((error) => console.log(error));
  };
  const showInterest = (status) => {
    let inputObj = { interested: status };
    ShowInterestIdea(authCtx.token, location.state.id, inputObj)
      .then((data) => {
        getTipDetails();
      })
      .catch((error) => console.log(error));
  };

  const validateForm = (formData) => {
    const errors = {};
    if (formData.tip === "") {
      errors.tip = "Title is Required";
    }
    if (formData.description === "") {
      errors.description = "Problem Statement is required";
    }
    if (formData.references === "") {
      errors.references = "References is Required";
    }
    if (authCtx.type === "admin" && formData.status === "") {
      errors.status = "Status is required";
    }
    return errors;
  };

  const onSelectTab = (i) => {
    setSelectedTab(i);
    if (i == 1) {
      setPageNo(1);
      getHistoryDetails();
      setVisible(15);
    }
  };

  const saveAttachments = async (files) => {
    await UploadAttachments(authCtx.token, files).then((res) => {
      const formValue = {
        ...formValues,
        attachment: [...tipDetails[0]?.attachment, ...res.data],
      };
      setFormValues(formValue);
      editTip(formValue);
    });
  };

  const editTip = async (inputValue) => {
    console.log(inputValue);
    const payload = { ...inputValue };
    if (authCtx.type !== "admin") {
      delete payload.status;
    }
    if (Object.keys(formErrors).length === 0) {
      let inputObj = {
        ...payload,
        adminComments: adminComments,
        userType: "admin",
      };
      await EditTipDetails(authCtx.token, location.state.id, inputObj)
        .then((data) => {
          if (data) {
            console.log(data);
            getTipDetails();
            setEdit(false);
          }
        })
        .catch((error) => console.log(error));
    }
  };

  const getHistoryDetails = () => {
    getTipHistory(authCtx, location.state.id, 1)
      .then((data) => {
        setHistoryData(data.records);
        setTotalRecords(data.totalRecord);
        setPageNo((prevValue) => {
          return prevValue + 1;
        });
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const handleShowMoreHistory = () => {
    if (visible >= historyData.length) {
      setTimeout(() => {
        getTipHistory(authCtx, props.tipId, pageNo)
          .then((data) => {
            setHistoryData(historyData.concat(data.records));
          })
          .catch((error) => {
            console.error("Error:", error);
          });
      }, 1000);
      setPageNo((prevValue) => {
        return prevValue + 1;
      });
    }
    setVisible((prevValue) => {
      return prevValue + 5;
    });
  };

  // const onAdminAction = () => {
  //   setIsAdminClickSave(true);
  //   let inputObj = {
  //     complexity: newComplexity,
  //     action: newStatus,
  //     adminComment: adminComments,
  //     roles: newStatus === "reject" ? [] : rolesList,
  //   };
  //   if (isValidRoles && adminComments) {
  //     approveTipDetail(authCtx.token, location.state.id, inputObj)
  //       .then((data) => {
  //         getTipDetails();
  //       })
  //       .catch((error) => {
  //         console.error("Error:", error);
  //       });
  //   }
  // };

  const onFileChange = (e) => {
    const files = e.target.files;
    const attachment = [...formValues.attachment];
    for (var i = 0, l = 4; i < l; i++) {
      const sizeInMB = (files[i]?.size / (1024 * 1024)).toFixed(2);
      if (sizeInMB <= 2) {
        attachment.push({ name: files[i].name, file: files[i] });
      }
    }
    setFormValues({ ...formValues, attachment });
  };
  const onFileDelete = (i) => {
    const attachment = [...formValues.attachment];
    attachment.splice(i, 1);
    setFormValues({ ...formValues, attachment });
  };

  const deleteTip = (tipid) => {
    DeleteTip(authCtx.token, tipid)
      .then((data) => {
        alert("Tip deleted");
        navigate("/CultureofLearning");
      })
      .catch((error) => console.log(error));
  };

  const onCancelEdit = () => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    setEdit(false);
    setFormValues(initialValues);
  };

  useEffect(() => {
    if (rolesFormValues.length) {
      const isvalid = rolesFormValues?.every(
        (item) => item.name && item.skills.length > 0
      );
      setIsValidToAdd(isvalid);
    }
  }, [rolesFormValues]);

  return (
    <Container style={{ marginTop: 30 }}>
      <div>
        <a href="/CultureofLearning" className="goback">
          <img src={arrowLeft} height="20" /> Back to Dashboard
        </a>
      </div>
      <Row style={{ marginTop: 40 }}>
        <Col
          xs={12}
          sm={12}
          md={4}
          lg={3}
          xl={3}
          xxl={3}
          style={{ marginBottom: 20 }}
        >
          <UserTipSidebar
            cancel={edit}
            data={tipDetails}
            reloadEvent={getTipDetails}
            uploadedFiles={formValues?.attachment}
            handleFileChange={onFileChange}
            handleFileDelete={onFileDelete}
            modalOpen={handleShow}
            modalClose={handleClose}
          ></UserTipSidebar>
        </Col>
        <Col xs={12} sm={12} md={8} lg={9} xl={9} xxl={9}>
          {authCtx.type === "admin" && (
            <Row className="tabContainer">
              <Col
                className="tabText"
                style={{
                  backgroundColor: selectedTab === 0 ? "#46B7E9" : "white",
                  color: selectedTab === 0 ? "#ffffff" : "#000000",
                }}
                onClick={() => onSelectTab(0)}
              >
                Tip Details
              </Col>
              <Col
                className="tabText"
                style={{
                  backgroundColor: selectedTab === 1 ? "#46B7E9" : "white",
                  color: selectedTab === 1 ? "#ffffff" : "#000000",
                }}
                onClick={() => onSelectTab(1)}
              >
                History
              </Col>
            </Row>
          )}
          {selectedTab === 0 && (
            <>
              {!edit && (
                <div>
                  <div className="tipDetail">
                    <p className="name">{tipDetails?.title}</p>
                    {((authCtx.type === "admin") ||
                      (authCtx.type === "user" && authCtx.email === tipDetails[0]?.email)) && (
                        <div
                          className="text-end"
                          onClick={() => {
                            console.log(formValues);
                            setEdit(true);
                            setNewStatus(tipDetails?.action);
                            setNewComplexity(tipDetails?.complexity);
                          }}
                        >
                          <img src={editIcon} alt="" className="editIcon" />
                        </div>
                      )}
                    {authCtx.type == "admin" && (
                      <div className="submit-your-tips py-3 delete-your-tip">
                        <Container>
                          <Row>
                            <Col>
                              <button
                                className="btn btn-warning submitTipBtn"
                                role="button"
                                onClick={() => deleteTip(tipDetails[0]?._id)}
                              >
                                Delete Tip
                              </button>
                            </Col>
                          </Row>
                        </Container>
                      </div>
                    )}
                    <Row className="ans">
                      <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                        <b>Where does your Tip fit in:</b>
                      </Col>
                      <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                        {tipDetails[0]?.tip}
                      </Col>
                    </Row>
                    <Row className="ans">
                      <Col
                        xs={12}
                        sm={12}
                        md={12}
                        lg={12}
                        xl={12}
                        xxl={12}
                        className="text-justify"
                      >
                        <b>Tell us more about your tip:</b>
                      </Col>
                      <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                        {tipDetails[0]?.description}
                      </Col>
                    </Row>
                    <Row className="ans">
                      <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                        <b>References</b>
                      </Col>
                      <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                        {tipDetails[0]?.references}
                      </Col>
                    </Row>
                    {authCtx.type === "admin" && (
                      <>
                        <Row className="ans">
                          <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                            <b>Status</b>
                          </Col>
                          <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                            {tipDetails[0]?.ReviewStatus !== undefined
                              ? tipDetails[0]?.ReviewStatus
                              : "NA"}
                          </Col>
                        </Row>
                        <Row className="ans">
                          <Col xs={12} sm={6} md={6} lg={2} xl={2} xxl={2}>
                            <b>Admin Comments:</b>
                          </Col>
                          <Col xs={12} sm={6} md={6} lg={10} xl={10} xxl={10}>
                            {tipDetails[0]?.adminComments}
                          </Col>
                        </Row>
                      </>
                    )}
                    <hr />
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        marginTop: 20,
                      }}
                    >
                      {authCtx.email?.toLowerCase() !==
                        tipDetails?.email?.toLowerCase() &&
                        (tipDetails?.status === "submitted" ||
                          tipDetails?.status === "inProgress") && (
                          <div
                            style={{
                              fontSize: 50,
                              marginTop: -14,
                              fontWeight: 50,
                              color: "gray",
                            }}
                          >
                            |
                          </div>
                        )}
                      {authCtx.email?.toLowerCase() !==
                        tipDetails?.email?.toLowerCase() &&
                        (tipDetails?.status === "submitted" ||
                          tipDetails?.status === "inProgress") && (
                          <div>
                            <img
                              src={
                                tipDetails?.isIntrested ? isInterested : hands
                              }
                              height="50"
                              onClick={() => {
                                showInterest(
                                  tipDetails?.isIntrested ? false : true
                                );
                              }}
                            />
                          </div>
                        )}
                      {authCtx.type === "admin" &&
                        tipDetails?.status !== "submitted" &&
                        authCtx.email?.toLowerCase() !==
                        tipDetails?.email?.toLowerCase() &&
                        tipDetails?.team?.length > 0 && (
                          <div
                            style={{
                              fontSize: 50,
                              marginTop: -14,
                              fontWeight: 50,
                              color: "gray",
                            }}
                          >
                            {" "}
                            |
                          </div>
                        )}
                    </div>
                    <Row>
                      <Alert
                        show={showSuccessMsg}
                        variant="success"
                        dismissible
                        onClose={() => setShowSuccessMsg(false)}
                      >
                        <p className="pointsSuccess">Points has been added</p>
                      </Alert>
                    </Row>
                  </div>

                  <Comments
                    id={tipDetails[0]?._id}
                    commentList={tipDetails[0]?.comments}
                    reloadEvent={getTipDetails}
                  />
                </div>
              )}
              {edit && (
                <div className="formBg">
                  <Row>
                    <Col>
                      <p className="edit-heading">
                        {isCloseIdea ? "Close Tip" : "Edit Tip"}
                      </p>
                      <hr />
                    </Col>
                  </Row>
                  <form onSubmit={submitForm} noValidate>
                    {authCtx.type === "admin" && <Row className="py-2">
                      <Col>
                        <TextArea
                          name="tip"
                          error={formErrors.tip}
                          InputData={formElements[0]}
                          onChange={onChange}
                          value={formValues?.tip}
                        />
                      </Col>
                    </Row>}
                    <Row className="py-2">
                      <Col>
                        <TextArea
                          name="description"
                          error={formErrors.description}
                          InputData={formElements[1]}
                          onChange={onChange}
                          value={formValues?.description}
                        />
                      </Col>
                    </Row>
                    <Row className="py-2">
                      <Col>
                        <TextArea
                          name="references"
                          error={formErrors.references}
                          InputData={formElements[2]}
                          onChange={onChange}
                          value={formValues?.references}
                        />
                      </Col>
                    </Row>
                    {authCtx.type === "admin" &&
                      tipDetails?.status !== "submitted" && (
                        <>
                          <Row className="py-2">
                            <Col xs={12} sm={12} md={12} lg={4} xl={4} xxl={4}>
                              <SearchDropDown
                                name="ReviewStatus"
                                error={formErrors.status}
                                InputData={formElements[3]}
                                DataList={reviewStatus}
                                onChange={onSelect}
                                value={{
                                  label: formValues?.ReviewStatus,
                                  value: formValues?.ReviewStatus,
                                }}
                              />
                            </Col>
                          </Row>
                          {((authCtx.type === "admin" &&
                            tipDetails?.status !== "submitted") ||
                            tipDetails?.status === "complete") && (
                              <Row className="ans">
                                <Col xs={12} sm={6} md={6} lg={2} xl={2} xxl={2}>
                                  <b>Admin Comment:</b>
                                </Col>
                                <Col
                                  xs={12}
                                  sm={6}
                                  md={6}
                                  lg={10}
                                  xl={10}
                                  xxl={10}
                                >
                                  {tipDetails?.adminComment}
                                </Col>
                              </Row>
                            )}
                          <Row className="py-2">
                            <Col>
                              <textarea
                                className="commentTextArea"
                                placeholder="Comments*"
                                name="approveComments"
                                rows="4"
                                onChange={(e) =>
                                  setAdminComments(e.target.value)
                                }
                              ></textarea>
                              {/* {(
                                <p className="errorMsg">*Comments is required</p>
                              )} */}
                            </Col>
                          </Row>
                          {/* <Row>
                              <Col>
                                <div className="d-flex justify-content-center">
                                  <button
                                    type="submit"
                                    className="btn btn-warning form-btns"
                                    onClick={() => onAdminAction()}
                                  >
                                    Save
                                  </button>
                                </div>
                              </Col>
                            </Row> */}
                        </>
                      )}
                    {/* {authCtx.type === "admin" &&
                        tipDetails?.status !== "submitted" && (
                          <Comments
                            id={tipDetails?._id}
                            adminCommentList={tipDetails?.adminComment}
                            reloadEvent={getTipDetails}
                          />
                        )} */}
                    <Row className="py-2 ">
                      <Col>
                        <div className="d-flex justify-content-center">
                          <button
                            type="submit"
                            className="btn btn-warning form-btns"
                          >
                            Save
                          </button>
                          &nbsp;&nbsp;
                          <button
                            type="reset"
                            className="btn btn-warning form-btns"
                            onClick={onCancelEdit}
                          >
                            Cancel
                          </button>
                          &nbsp;&nbsp;
                        </div>
                      </Col>
                    </Row>
                  </form>
                </div>
              )}
            </>
          )}
          {selectedTab === 1 && (
            <>
              <History
                historyData={historyData}
                visible={visible}
                handleShowMoreHistory={handleShowMoreHistory}
                totalRecords={totalRecords}
              ></History>
            </>
          )}
        </Col>
      </Row>
    </Container>
  );
};
export default TipDetail;
