import { API_URL } from "../config/config";

const headerConfig = (token) => {
  let headerObj = {
    'Authorization': `Bearer ${token}`,
    'Accept': "application/json",
    'Content-Type': "application/json",
  };

  return headerObj;
};

export const GetTipDetails = async (token, id) => {
  const response = await fetch(`${API_URL}/tip?tipId=${id}`, {
    method: "GET",
    headers: headerConfig(token),
  });
  const jsonData = await response.json();
  if (!response.ok) {
    console.log("API error");
    return;
  }
  return jsonData;
};

export const EditTipDetails = async (token, id, payload) => {
  const response = await fetch(`${API_URL}/tip?id=${id}`, {
    method: 'PUT',
    headers: headerConfig(token),
    body: JSON.stringify(payload)
  });
  const jsonData = await response.json();
  if (!response.ok) {
    console.log("API error");
    return;
  }
  return jsonData;
};

export const AddComments = async (token, id, payload) => {
  console.log(payload);
  const response = await fetch(`${API_URL}/tipcomment?tipId=${id}`, {
    // http://127.0.0.1:3000/api/tipComment?tipId=66bc8b2422c4e13e7635fe29
    method: 'POST',
    headers: headerConfig(token),
    body: JSON.stringify(payload)
  });
  const jsonData = await response.json();
  if (!response.ok) {
    console.log("API error");
    return;
  }
  return jsonData;
};

export const LikeComments = async (token, id, payload) => {
  const response = await fetch(`${API_URL}/tipcomment/like?tipcommentId=${id}`, {
    method: 'POST',
    headers: headerConfig(token),
    body: JSON.stringify(payload)
  });
  const jsonData = await response.json();
  if (!response.ok) {
    console.log("API error");
    return;
  }
  return jsonData;
};

export const approveTipDetail = async (token, ideaId, inputObj) => {
  const response = await fetch(`${API_URL}/idea/${ideaId}`,
    {
      method: 'POST',
      headers: headerConfig(token),
      body: JSON.stringify(inputObj)
    });
  const jsonData = await response.json();
  if (!response.ok) {
    console.log("API error");
    return;
  }
  return jsonData;
}

export const LikeIdea = async (token, ideaId, inputObj) => {
  const response = await fetch(`${API_URL}/idea/like?id=${ideaId}`,
    {
      method: 'POST',
      headers: headerConfig(token),
      body: JSON.stringify(inputObj)
    });
  const jsonData = await response.json();
  if (!response.ok) {
    console.log("API error");
    return;
  }
  return jsonData;
}

export const ShowInterestIdea = async (token, ideaId, inputObj) => {
  const response = await fetch(`${API_URL}/idea/interest?id=${ideaId}`,
    {
      method: 'POST',
      headers: headerConfig(token),
      body: JSON.stringify(inputObj)
    });
  const jsonData = await response.json();
  if (!response.ok) {
    console.log("API error");
    return;
  }
  return jsonData;
}

export const GetIdeaLikes = async (token, id) => {
  const response = await fetch(`${API_URL}/idea/likesList?id=${id}`, {
    method: "GET",
    headers: headerConfig(token),
  });
  const jsonData = await response.json();

  if (!response.ok) {
    console.log("API error");
    return;
  }
  return jsonData;
};

export const GetIdeaInterested = async (token, id) => {
  const response = await fetch(`${API_URL}/idea/interestedList?id=${id}`, {
    method: "GET",
    headers: headerConfig(token),
  });
  const jsonData = await response.json();

  if (!response.ok) {
    console.log("API error");
    return;
  }
  return jsonData;
};