import { API_URL } from "../config/config";

export const UploadAttachments = async (token, payload) => {
  // change to API call here instead of json file
  const response = await fetch(`${API_URL}/attachment`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
    },
    body: payload,
  });
  const jsonData = await response.json();

  if (!response.ok) {
    console.log("API error");
    return;
  }
  return jsonData;
};

export const CreateTips = async (token, payload) => {
  const response = await fetch(`${API_URL}/tip`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(payload),
  });
  const jsonData = await response.json();
  if (!response.ok) {
    console.log("API error");
    return;
  }
  return jsonData;
};

export const GetTip = async (token) => {
  try {
    const response = await fetch(`${API_URL}/tip/list`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });

    if (!response.ok) {
      throw new Error("Failed to fetch ideas");
    }

    return await response.json();
  } catch (err) {
    console.log(err);
  }
};
export const DeleteTip = async (token, tipid) => {
  try {
    const response = await fetch(`${API_URL}/tip?_id=${tipid}`, {
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });

    if (!response.ok) {
      throw new Error("Failed to Delete Tip");
    }

    return await response.json();
  } catch (err) {
    console.log(err);
  }
};

// Tips search
export const tipSearchDetails = async (token, type, text) => {
  let payload;
  if (type === undefined) {
    //   payload = { query: text };
    payload = { type: "tips", text: text };
  } else {
    payload = { type: type, text: text };
  }

  const response = await fetch(`${API_URL}/tip/tipsearch`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(payload),
  });
  const jsonData = await response.json();

  if (!response.ok) {
    console.log("API error");
    return;
  }
  return jsonData;
};
